import React, { ButtonHTMLAttributes, ReactNode } from 'react'

import styled from 'styled-components'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * Is this the principal call to action on the page?
   */
  primary?: boolean;
  /**
   * What background color to use
   */
  flat?: boolean;
  /**
   * What background color to use
   */
  backgroundColor?: string;
  /**
   * What color to use
   */
  color?: string;
  /**
   * text align
   */
  align?: 'center' | 'right' | 'left';
  /**
   * How large should the button be?
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * Button contents
   */
  label?: string;
  /**
   * Optional click handler
   */
  onClick?: () => void;
  /**
   * incase you want something more complex than string label
   */
  children?: ReactNode;
}

const sizes = { small: 0, medium: 1, large: 2 }

/**
 * Primary UI component for user interaction
 */
export const Button: React.FC<ButtonProps> = ({
  primary = false,
  flat = false,
  size = 'medium',
  backgroundColor,
  color,
  label,
  children = null,
  align = 'center',
  ...props
}) => {
  return (
    <Styled
      align={align}
      primary={!!primary}
      flat={!!flat}
      type="button"
      style={{ backgroundColor, color }}
      size={sizes[size] || 0}
      backgroundColor={backgroundColor}
      color={color}
      {...props}
    >
      {label || children}
    </Styled>
  )
}

interface Props extends Omit<ButtonProps, 'size'> {
  size: number;
}
const Styled = styled.button<Props>`
  min-width: 148px;
  border-radius: 4px;
  font-family: "TeleNeoWeb",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  border: 0;
  outline: none;
  cursor: pointer;
  display: inline-block;
  text-align: ${props => props.align};
  ${calcColors}
  ${calcSpaces}
`
function calcSpaces ({ size }:Props) {
  const r = {
    fontSize: 16 + (size * 2),
    padding: `${size + 10}px ${16 + (size * 4)}px`
  }

  return r
}

function calcColors ({ primary, flat, backgroundColor = 'var(--primary, #e20074)', color = '#FFF' }:Props) : any {
  const r = {
    backgroundColor: primary ? backgroundColor : '#FFF',
    color: color || primary ? color : 'var(--dark, #222)',
    boxShadow: '0 3px 6px 0 rgba(0,0,0,.16);',
    fontWeight: '700',
    border: '1px solid',
    borderColor: 'transparent'
  }

  if (color) {
    if (color[0] === '#' || color.indexOf('rgb') === 0) {
      r.color = color
    } else {
      r.color = `var(--${color}, #222222)`
    }
  }

  if (!primary) {
    r.border = '1px solid ' + r.color
  }

  if (flat) {
    r.color = backgroundColor
    r.borderColor = backgroundColor
    r.backgroundColor = color
    r.boxShadow = 'none'
    r.fontWeight = 'normal'
    console.log('im flat')
  }
  console.log(r)
  return r
}
