import { configureStore, Action } from '@reduxjs/toolkit'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { ThunkAction } from 'redux-thunk'

import rootReducer from './rootReducer'

const client = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_HOST}/retail_cms/api/v1/`
})

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: client
      }
    // serializableCheck: false,
    })
})

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default
    store.replaceReducer(newRootReducer)
  })
}

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppThunk<R=void> = ThunkAction<R, RootState, typeof client, Action<string>>

export const useAppDispatch = () => useDispatch<AppDispatch>()

export default store
