import React from 'react'

import { connect } from 'react-redux'

import { de } from '../../i18n'
import { RootState } from '../../store'
import { ptSelectors } from '../../store/pricingTool'
import P from './TestPreview'

import './_preview.scss'

const scales = ['1', '.75', '.5', '.3', '.1']
const Preview:React.FC = () => {
  const [zoom, setZoom] = React.useState<string>('0')
  function toggle (arg:1|-1|string) {
    const v = typeof arg === 'string' ? arg : Number(zoom) + arg
    let idx = '0'
    if (scales[Number(v)]) {
      idx = `${v}`
    }
    setZoom(idx)
  }
  return (
    <aside id="pricing--preview" style={{ zoom: scales[Number(zoom)] }}>
      <header>
        <div>
          <button type="button" onClick={toggle.bind(this, -1)}>
            +
          </button>
          <button type="button" onClick={toggle.bind(this, 1)}>
            -
          </button>
        </div>
        <span style={{ flex: 1 }}>{de.vorschau}</span>
        <select value={zoom} onChange={e => toggle(e.target.value)}>
          {scales.map((val, idx) => <option key={val} value={idx}>{Number(val) * 100}%</option>)}
        </select>
      </header>

      <article id="preview">
        <P />
      </article>
    </aside>
  )
}
const PreviewContainer = connect((state:RootState) => ({
  mock: ptSelectors.preview(state)
}))(Preview)

export default PreviewContainer
