import React from 'react'

import { TelecomSelect } from '@smart/design-system'
import { useSelector } from 'react-redux'

import { de } from '../i18n'
import { useAppDispatch } from '../store'
import { ptActions, ptSelectors } from '../store/pricingTool'

export default React.memo(function DeviceSelection () {
  const dispatch = useAppDispatch()
  const form = useSelector(ptSelectors.form)
  const { types, companies, devices } = useSelector(ptSelectors.entities)

  const companyIds = types[form.categoryId || 'not-yet']?.companies || []
  const deviceIds = useSelector(ptSelectors.models)

  const updateType = React.useCallback(
    (e) =>
      dispatch(
        ptActions.inputChange({
          categoryId: e.target.value,
          companyId: '',
          deviceId: ''
        })
      ),
    [dispatch]
  )
  const updateCompany = React.useCallback(
    (e) => dispatch(ptActions.inputChange({ companyId: e.target.value, deviceId: '' })),
    [dispatch]
  )
  const updateDevice = React.useCallback(
    (e) => dispatch(ptActions.inputChange({ deviceId: e.target.value })),
    [dispatch]
  )

  return (
    <div style={{ fontSize: 12 }}>
      <TelecomSelect
        placeholder={de.preview.geratetyp}
        value={`${form.categoryId}`}
        onChange={updateType}
        values={Object.values(types)}
      >{(row) => <option key={row.id} value={row.id}>{row.title}</option>}</TelecomSelect>

      <TelecomSelect
        disabled={!form.categoryId}
        placeholder={de.preview.hersteller}
        value={`${form.companyId}`}
        onChange={updateCompany}
        values={companyIds}
      >{(row) => <option key={row} value={row}>{companies[row].title}</option>}</TelecomSelect>

      <TelecomSelect
        disabled={!form.companyId}
        placeholder={de.preview.modell}
        value={`${form.deviceId}`}
        onChange={updateDevice}
        values={deviceIds}
      >{(row) => <option key={row} value={row}>{devices[row].description}</option>}</TelecomSelect>

    </div>
  )
})
