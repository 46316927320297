import React, { FC, HTMLAttributes, ReactNode, useEffect } from 'react'

import styled from 'styled-components'
import { useAsyncResource } from 'use-async-resource'

import { ErrorBoundary } from '../ErrorBoundary'

export interface SelectProps extends HTMLAttributes<HTMLSelectElement> {
  placeholder: string;
  src?: string
  values?: Array<any>
  children?: (r: any) => ReactNode,
  value?: string;
  onChange: (r:any)=>void;
  disabled?: boolean;
}

interface SP extends SelectProps{
  getData: any;
}

const Loading = () => {
  return <h5>loading...</h5>
}

const getValues = (url?:string, values?: any[]) =>
  url
    ? fetch(url)
      .then(response => response.ok ? response.json() : [])
    : Promise.resolve(values || [])

export const TelecomSelect: FC<SelectProps> = ({ src, values, ...props }) => {
  const [dataReader, start] = useAsyncResource(getValues, src, values)

  useEffect(() => {
    if (src) {
      start(src, values)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src, values])

  return (

    <SelectContainer disabled={!!props.disabled} data-testid="select">
      <ErrorBoundary fallback={(e) => 'something wrong happened !' + e.error?.message}>
        <React.Suspense fallback={<Loading />}>
          <Select
            {...props}
            getData={dataReader} />
        </React.Suspense>
      </ErrorBoundary>
    </SelectContainer>
  )
}

function Select ({ getData, children, disabled, ...rest }:SP) {
  const data = getData() // 😎

  return <SelectHtml
    aria-label={rest.placeholder}
    disabled={disabled || data.length === 0} {...rest}>
    <option value={''}>{rest.placeholder}</option>
    {data.map(children)}
  </SelectHtml>
}

const SelectHtml = styled.select`

`

const SelectContainer = styled.div<{disabled?:boolean}>`
display: block;
width: 100%;
font-weight: 400;
line-height: 1.3em;
color: #4b4b4b;
background-color: ${props => props.disabled ? 'var(--gray-300, #4b4b4b)' : '#fff'};
background-clip: padding-box;
border: 1px solid rgba(0,0,0,.3);
border-radius: 4px;
transition: all .15s ease-in-out;
margin-bottom: 1em;
select:disabled{
  background-color: var(--gray-300, #4b4b4b);
  cursor: not-allowed;
}
> * {
  margin: 0 auto;
  padding: .5em 0;
  display: block;
  width: calc(100% - 2em);
  height: 100%;
  border: none;
}
`
