import { FC } from 'react'

import { scaleUnitToPx } from '@smart/design-system'
import { connect } from 'react-redux'

import '@smart/design-system/src/styles/global.css'
import TmobileLogo from '../../imgs/tmobile_new.svg'
import { RootState } from '../../store'
import { ptSelectors } from '../../store/pricingTool'
import { PreviewState } from '../../store/pricingTool/selectors'

const TestPreview:FC<{ mock: PreviewState, scale?:number }> = ({ mock, scale = 300 }) => {
  let orin = 'verti'
  const s = {
    transform: 'scale(1)',
    filter: `grayscale(${mock.colors === '1' ? 0 : 1})`,
    border: mock.cuttingMarks === '1' ? 'dashed 1rem' : 'none',
    // height: mock.size.h + mock.size.unit,
    // width: mock.size.w + mock.size.unit
    height: 'calc(297mm - 2.6em)',
    width: '210mm'
  }
  if (mock.orientation !== '0') {
    orin = 'horiz'
    s.width = '297mm'
    s.height = '210mm'
  }
  const zoom = scaleUnitToPx(s.width, scale)
  s.transform = `scale(${zoom})`

  return <div id="print" style={{ position: 'relative' }}>
    <div className={`paper ${orin}`} style={s}>

      <div>
        <h1>{mock.company?.replace('ß', 'ẞ')}</h1>
        <h2>{mock.device?.replace('ß', 'ẞ')}</h2>
      </div>
      <div style={{ flex: 1, alignItems: 'center', display: 'flex', width: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <ul style={{ flex: 1 }}>
            {(mock.features || []).map((f) => (
              <li key={f}>{f}</li>
            ))}
          </ul>
          <div className="storer">{mock.promo && <img alt="promo" src={mock.promo} />}</div>
        </div>
      </div>
      {mock.tariffOption && <p>Preis im Tarif {mock.tariffOption}</p>}
      <div className="prices">
        {mock.oldPrice && (
          <div className="oldprice">
            <span>vorher</span>
            <h1 className="value cross"><b>{mock.oldPrice + ' € '}</b></h1>
          </div>
        )}
        {mock.price && (
          <div className="price">
            <span>jetzt</span>
            <h1 className="value">{mock.price + ' € '}</h1>
          </div>
        )}

        {mock.priceNotarif && (
          <h5>
            {'Preis ohne Vertrag'} {mock.priceNotarif} {'€'}
          </h5>
        )}
      </div>
      <footer>
        <img src={TmobileLogo} alt="telecome" />
      </footer>

    </div>
  </div>
}
const PreviewContainer = connect((state:RootState) => ({
  mock: ptSelectors.preview(state)
}))(TestPreview)

export default PreviewContainer
