import { batch } from 'react-redux'

import { AppThunk } from './../index'
import { ptSlice, Device, TariffApi, PromoLogo } from './reducer'
import { updatedAt } from './selectors'
import { validate } from './validate'

const { actions } = ptSlice

export function movePage (payload: number): AppThunk {
  return (dispatch, getState) => {
    const state = getState()

    for (let i = 0; i < payload; i++) {
      const isValid = validate[i] ? validate[i](state) : { success: true }

      if (isValid.success === false) {
        console.error(`I'm a German error message, i'm here to tell you to check page ${i + 1}`, isValid)
        return dispatch(actions.hasError(isValid.error.errors))
      }
    }
    dispatch(ptSlice.actions.setStep(payload))
  }
}

// Thunk Action Creators
export function getDevices ():AppThunk {
  return (dispatch, getState, client) =>
    client
      .get('devices.json?since=' + updatedAt(getState()))
      .then(r => r.data)
      .catch(() => import('../../seed/devices').then(r => r.default))
      .then((data : Device[]) => { dispatch(actions.resetDevices(data)) })
}

export function getTariffs () :AppThunk {
  return (dispatch, getState, client) =>
    client
      .get('tariffs.json?since=' + updatedAt(getState()))
      .then(r => r.data)
      .catch(() => import('../../seed/tariffs').then(r => r.default))
      .then((data : TariffApi) => { dispatch(actions.resetTariffs(data)) })
}

export function getPromos () :AppThunk {
  return (dispatch, getState, client) =>
    client
      .get('promo_logos.json?since=' + updatedAt(getState()))
      .then(r => r.data)
      .catch(() => import('../../seed/promos').then(r => r.default))
      .then((data : PromoLogo[]) => {
        dispatch(actions.resetPromoLogos(data))
      })
}

export function loadData ():AppThunk {
  return (dispatch) => {
    return batch(() => {
      dispatch(getDevices())
      dispatch(getTariffs())
      dispatch(getPromos())
    })
  }
}
