import React from 'react'

import get from 'lodash/get'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { de } from '../i18n'
import { ptSelectors } from '../store/pricingTool'

export default function Step4 () {
  const data = useSelector(ptSelectors.form)
  const src = useSelector(ptSelectors.entities)
  const sizes = useSelector(ptSelectors.sizes)

  return (
    <section>
      <h1>{de.form.page4}: </h1>
      <h5>{de.sidebar.zusammenfassung}</h5>
      <Table>
        <li>
          <label>{de.preview.seitenausrichtung}</label>
          <span>
            {data.orientation ? de.landscape : de.portrait}
          </span>
        </li>
        <li>
          <label>{de.preview.seitenformat}</label>
          <span>
            {Array.from(sizes || [])
              .map((r) => r.id)
              .join(', ')}
          </span>
        </li>
        <li>
          <label>{de.preview.geratetyp}</label>
          <span> {get(src.types, data.categoryId || '', { title: 'N/A' }).title} </span>
        </li>
        <li>
          <label>{de.preview.hersteller}</label>
          <span> {get(src.companies, data.companyId || '', { title: 'N/A' }).title} </span>
        </li>
        <li>
          <label>{de.preview.modell}</label>
          <span> {get(src.devices, data.deviceId || '', { description: 'N/A' }).description}</span>
        </li>

        <li>
          <label>{de.preview.Tarif}</label> <span> {data.tariffId ? src.tariffs.byId[data.tariffId].name : '--'}</span>
        </li>
        <li>
          <label>{de.preview.Tarifoption}</label>
          <span> {data.tariffOption ? src.tariffsOptions.byId[data.tariffOption].name : '--'}</span>
        </li>

        <li>
          <label>{de.preis}</label>
          <span> {data.price && `${data.price} €`} </span>
        </li>
        <li>
          <label>{de.streichpreis}</label>
          <span> {data.oldPrice && `${data.oldPrice} €`} </span>
        </li>
        <li>
          <label>{de.preis_vertrag}</label>
          <span> {data.priceNotarif && `${data.priceNotarif} €`} </span>
        </li>
        <li>
          <label>{'Störer Darstellungsoption'}</label>
          <span>
            {data.promo ? <img src={data.promo} className="avatar xs" alt="Ihr Logo" /> : '-'}
          </span>
        </li>
        <li>
          <label>{de.preview.Farb}</label>
          <span> {data.colors === '1' ? 'Farbe' : 'Schwarz-weiß'} </span>
        </li>
        <li>
          <label>{de.preview.schnittmarken}</label>
          <span> {data.cuttingMarks === '1' ? 'Ja' : 'Nein'} </span>
        </li>
      </Table>
    </section>
  )
}

const Table = styled.ul`
list-style: none;
margin:0;
padding:0;

li > label{
  text-align: left;
  font-weight: bold;
}
li > span{
  text-align: right;
}
li{
  display: flex;
  padding: 0 0 .5rem 0;
  justify-content: space-between;
  border-bottom: 1px solid #CCC;
}
li img{
  max-width: 40px;
  max-height: 40px;
}
`
