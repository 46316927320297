import React, { FC } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import logo from '../../assets/brand/dt-logo.svg'
import './footer.scss'

const currentYear = new Date().getFullYear()

export interface FooterLink {
  name: string;
  slug: string;
}

export interface FooterProps {
  pages?: FooterLink[];
  as?: any;
  host?: string;
}
/* istanbul ignore next */
export const AppFooter: FC<FooterProps> = ({ pages = [], as, host = '' }) => (
  <footer className="footer" data-testid="footer">
    <div className="container">
      <div className="brand-images">
        <div className="brand-logo">
          <img src={logo} alt="Telekom Logo" />
          <span className="sr-only">Telekom Logo</span>
        </div>
      </div>
      <div className="footer-links">
        {pages && pages.length > 0 && (
          <div className="pages-links">
            {pages.map((page) => (
              <A
                as={as}
                href={`${host}/static_page/${page.slug}`}
                rel="noopener noreferrer"
                className="text-light"
                key={page.slug}
              >
                {page.name}
              </A>
            ))}
          </div>
        )}
        <div className="copyright">
          <p> {`© ${currentYear} Deutsche Telekom AG`} </p>
        </div>
      </div>
    </div>
  </footer>
)

AppFooter.propTypes = {
  pages: PropTypes.array
}

AppFooter.defaultProps = {
  pages: [],
  as: 'a'
}

const A = styled.a`
  margin-right: 30px;
  text-decoration: none;
  text-transform: capitalize;
  color: #fafafa !important;
  &:hover {
    color: #fff !important;
  }
`
