import React from 'react'

import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { de } from '../i18n'
import { useAppDispatch } from '../store'
import { ptActions, ptSelectors } from '../store/pricingTool'

const steps: [string, string[]][] = [
  [
    de.sidebar.seitenverhaltnis,
    [de.sidebar.ausrichtung, de.sidebar.Formate]
  ],
  [
    de.sidebar.informationen,
    [
      de.sidebar.gerateinfo,
      de.sidebar.tarifinfo,
      de.sidebar.preiskommunikation,
      de.sidebar.zusatz
    ]
  ],
  [de.sidebar.druck, []],
  [de.sidebar.zusammenfassung, []]
]

const PricingSteps = () => {
  const dispatch = useAppDispatch()
  const step = useSelector(ptSelectors.step)
  const setStep = React.useCallback((payload) => dispatch(ptActions.movePage(payload)), [
    dispatch
  ])
  return (
    <Aside>
      <div
        style={{ position: 'relative' }}
      >
        <H2>{de.sidebar.preisschilder}</H2>
        <ol>
          {steps.map((row, idx) => (<Li key={row[0]}>
            <button
              onClick={() => setStep(idx)}
              className={step === idx ? 'active' : ''} >
              {row[0]}</button>
            <ul>
              {row[1].map((s) => (
                <li key={s}>{ s }</li>
              ))}
            </ul>
          </Li>
          ))}
        </ol>
      </div>
    </Aside>
  )
}

export default PricingSteps

const H2 = styled.h2`
  font: normal normal 800 1.2rem/1.3rem TeleNeoWeb;
`
const Aside = styled.aside`
max-width: 300px;

li button.active{
  color: var(--primary);
  font-weight: 800;
}

`
const Li = styled.li`
> button{
  background-color: transparent;
  outline: none;
  border: none;
}
> ul{
  list-style: none;
  margin-top:1em;
  padding-left: .5em;
}
`
