import React from 'react'

import { Col } from '@smart/design-system'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { DeviceSelection, TariffSelection } from '../Components'
import Warning from '../Components/Warning'
import { de } from '../i18n'
import { useAppDispatch } from '../store'
import { ptActions, ptSelectors } from '../store/pricingTool'

function Step2 () {
  const dispatch = useAppDispatch()
  const form = useSelector(ptSelectors.form)
  const logos = useSelector(ptSelectors.promoLogos)

  const handleChange = React.useCallback(
    (e) => {
      dispatch(ptActions.inputChange({ [e.target.name]: e.target.value }))
    },
    [dispatch]
  )
  const setPromo = React.useCallback(
    (promo) => {
      dispatch(ptActions.inputChange({ promo }))
    },
    [dispatch]
  )
  return (
    <section style={{ margin: '-.5rem 1rem 0 2rem' }}>
      <h1>{de.form.page2}: </h1>
      <Col>
        <h5 >{de.sidebar.gerateinfo}</h5>

        <Warning of="deviceId" />
        <DeviceSelection />

        <h5 style={{ marginTop: '4rem' }}>{de.sidebar.tarifinfo}</h5>
        <TariffSelection />

        <h5 style={{ marginTop: '3rem' }}>{de.sidebar.preiskommunikation}</h5>

        <Warning of="price" />
        <Label htmlFor="price">
          <span>{de.preis}</span>
          <Input
            onChange={handleChange}
            name="price"
            id="price"
            type="number"
            step="any"
            value={form.price || ''}
            placeholder={de.preis_holder}
            className="form-control"
          /> </Label>

        <Label htmlFor="oldPrice">
          <span>{de.streichpreis} ( {de.optional} )</span>

          <Input
            onChange={handleChange}
            name="oldPrice"
            id="oldPrice"
            type="number"
            step="any"
            value={form.oldPrice || ''}
            placeholder={de.preis_holder}
            className="form-control"
          />
        </Label>

        <Warning of="priceNotarif" />
        <Label htmlFor="priceNotarif">
          <span>{de.preis_vertrag}</span>
          <Input
            onChange={handleChange}
            name="priceNotarif"
            id="priceNotarif"
            type="number"
            step="any"
            value={form.priceNotarif || ''}
            placeholder={de.preis_holder}
            className="form-control"
          /></Label>

        <h5 style={{ marginTop: '3rem' }}>
          {de.sidebar.zusatz} ( {de.optional} )
        </h5>
        <Tiles >
          {logos.filter(i => typeof i.image_url === 'string').map(({ image_url: n }) => (
            <button
              key={n}
              type="button"
              onClick={() => setPromo(n === form.promo ? null : n)}
              className={` ${n === form.promo ? 'active' : ''}`}>
              <img crossOrigin="anonymous" src={n} height="90%" alt={'promo logo'} />
            </button>
          ))}
        </Tiles>
      </Col>
    </section>
  )
}
export default React.memo(Step2)

const Tiles = styled.div`
margin: 0;
max-width: 600px;
display: grid;
grid-gap: 1rem;
grid-template-columns: repeat(auto-fit,minmax(250px,1fr));

> button > img{
  background-color: var(--gray-300, #b3b3b3);
  border-radius: 100%;
}

> button{
  background-color: #FFF;
  border-radius: 4px;
  min-width: 150px;
  border: 1px solid #222;
  height: 150px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}
 button.active{
  border: 2px solid var(--primary);
  font-weight: 700;
}
button span{
  font-weight: normal;
}
`
const Input = styled.input`
  line-height: 2rem;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #8C8C8C;
  border-radius: 3px;
  margin-left: 2rem;
  font-size: 1.5rem;
  width: 50%;
`
const Label = styled.label`
display: flex;
flex-flow: row wrap;
width: 100%;
align-items: center;
justify-content: space-between;
margin-bottom: 1em;
${Input}{
  min-width: 150px;
  max-width: 70%;
  flex:1;
}
`
