import React, {
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'

import styled from 'styled-components'

import logo from '../../assets/brand/dt-logo.svg'
export interface HeaderProps {
  /**
   * name to display under banner
   */
  subheader?: string;
}
let d = -1
export function AppHeader ({ subheader }: HeaderProps): ReactElement {
  const [isSticky, setSticky] = useState(false)
  const id = useRef<string>('a' + (Date.now() * Math.random() + 1) + '')
    .current

  const handleScroll = useCallback(() => {
    if (d !== -1) {
      clearTimeout(d)
    }
    d = setTimeout(() => {
      const measure = document.getElementById(id)?.getBoundingClientRect()

      if (!measure || measure.y === undefined || measure.height === undefined) {
        return
      }

      const offset = Math.max(measure.y, measure.height, 60)
      const shouldStick = window.scrollY >= offset

      if (isSticky !== shouldStick) {
        setSticky(shouldStick)
      }
    }, d !== -1 ? 1 : 30)
  }, [isSticky, id])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return (
    <Fragment>
      <Container data-testid="appheader">
        <div>
          <div className="brand-logo">
            <a href="/">
              <img src={logo} alt="" />
              <span className="sr-only">Telekom Logo</span>
            </a>
          </div>
        </div>
      </Container>
      {isSticky && (
        <div
          data-testid="placeholder"
          style={{ height: document.getElementById(id)?.offsetHeight }}
        />
      )}
      {(subheader && typeof subheader === 'string') && (
        <SubHeader data-testid="SubHeader" id={id} isSticky={!!isSticky}>
          <h1>
            {subheader.split(' ')[0]}{' '}
            <b>{subheader.split(' ').slice(1).join(' ')}</b>
          </h1>
        </SubHeader>
      )}
    </Fragment>
  )
}

const Container = styled.div`
  min-height: 7rem;
  max-height: 10vh;
  background-color: var(--magenta);
  align-items: center;
  display: flex;
  margin: 0;
  padding: 0 1rem;
  z-index: 8;
  > div {
    margin: 0 auto;
    max-width: var(--max-width, 1200px);
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    flex: 1;
  }

  .brand-logo  {
    img { color: #ffffff;
      height: 38px;
    }
  }
`

const SubHeader = styled.div<{ isSticky: boolean }>`
  position: relative;
  position: sticky;
  top: 0;
  min-height: 7rem;
  max-height: 10vh;
  padding: 1.3rem;
  background-color: #fff;
  border-top: 4px solid var(--magenta, #e20074);
  border-bottom: 1px solid var(--grey-250, #dcdcdc);
  display: flex;
  align-items: center;
  z-index: 9;
  ${({ isSticky }) =>
    isSticky &&
    `
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;`}
  > h1 {
    margin: 0 auto;
    max-width: var(--max-width, 1200px);
    width: 100%;
    display: block;
    padding: 0;
    font-size: 1.4rem;
    letter-spacing: -1px;
    font-weight: 400;
    text-transform: uppercase;
  }
  > h1 > b {
    font-weight: 700;
  }
`
