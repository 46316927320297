
import { FC } from 'react'

import { connect } from 'react-redux'
import styled from 'styled-components'

import Warning from '../Components/Warning'
import { de } from '../i18n'
import FileOutline2 from '../imgs/f1.svg'
import FileOutline from '../imgs/f2.svg'
import { AppDispatch, RootState } from '../store'
import { ptActions, ptSelectors, SliceState } from '../store/pricingTool'

const Step1:FC<IStep1> = ({ orientation, sizes, error, setInputValue, toggleSize }) => {
  return (
    <section style={{ margin: '-.5rem 1rem 0 2rem' }}>
      <h1>{de.form.page1}: </h1>

      <h5>{de.sidebar.ausrichtung}</h5>

      <Radios>
        <label htmlFor="orientation" style={{ flex: 1 }}>
          <input
            checked={orientation === '0'}
            onChange={() => setInputValue({ orientation: '0' })}
            type="radio"
            value="0"
            name="orientation"
            id="orientation"
          />
          <img src={FileOutline} alt={de.form.hochformat}/>
          {de.form.hochformat}
        </label>

        <label htmlFor="orientation2" style={{ flex: 1 }}>
          <input
            checked={orientation === '1'}
            onChange={() => setInputValue({ orientation: '1' })}
            type="radio"
            value="1"
            name="orientation2"
            id="orientation2"
          />
          <img src={FileOutline2} alt="de.form.querformat"/>
          {de.form.querformat}
        </label>
      </Radios>

      <div>
        <h5>{de.Formate}</h5>
        <Warning of="sizes" />
        <Tiles>
          {sizes.map((size) => {
            const { id, w, h, unit, selected } = size
            return (
              <button
                key={id}
                type="button"
                onClick={() => toggleSize(id)}
                className={`${selected ? 'active' : ''}`}
              >
                {id}
                <span>
                  {w}x{h}
                  {unit}
                </span>
              </button>
            )
          })}
        </Tiles>
      </div>
    </section>
  )
}

const mapStateToProps = (state:RootState) => {
  const {
    form: { orientation = '0' },
    sizes
  } = ptSelectors.slice(state)
  return { orientation, sizes, error: ptSelectors.errors(state) }
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setInputValue: (payload: Partial<SliceState['form']>) => dispatch(ptActions.inputChange(payload)),
  toggleSize: (id:string) => dispatch(ptActions.toggleSize(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(Step1)

type IStep1 = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const Radios = styled.div`
margin-bottom: 24px;
display: flex;
font-weight: bold;
font-size: 1rem;
vertical-align: center;
label img{
  max-width:30px;
  max-height:30px;
  position:relative;
  top: .5em;
  margin: 0 .5em;
}
`
const Tiles = styled.div`
margin: 0;
max-width: 600px;
display: grid;
grid-gap: 1rem;
grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
> button{
  background-color: #FFF;
  border-radius: 4px;
  min-width: 150px;
  border: 1px solid #222;
  height: 150px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}
 button.active{
  border: 2px solid var(--primary);
  font-weight: 700;
}
button span{
  font-weight: normal;
}
`
