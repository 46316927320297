import pick from 'lodash/pick'

import { RootState } from '../'
import ptSlice, { Size, SliceState, initialState, Entities } from './reducer'

// selectors
// @@TODO: convert to use reselect to better performace
export function slice (state: RootState): SliceState {
  return state[ptSlice.name] || initialState
}
export function updatedAt (state: RootState) {
  return slice(state).updatedAt
}

export function step (state: RootState) {
  return slice(state).step
}

export function form (state: RootState) {
  return slice(state).form
}

export function entities (state: RootState) {
  return slice(state).entities
}

export function entity (name: keyof Entities) {
  return (state: RootState) => entities(state)[name] || initialState.entities[name]
}

export function sizes (state: RootState) {
  return slice(state).sizes.filter((r) => !!r.selected)
}

export function promoLogos (state: RootState) {
  const imgs = (slice(state).promoLogos || []).map(logo => ({
    ...logo,
    image_url: logo.static ? logo.image_url : (process.env.REACT_APP_BACKEND_HOST + logo.image_url),
    updated_at: new Date(logo.updated_at),
    created_at: new Date(logo.created_at)
  }))

  return imgs
}

export function preview (state: RootState): PreviewState {
  const data = form(state)
  const e = entities(state)
  return {
    category: data.categoryId ? e.types[data.categoryId].title : '',
    company: data.companyId ? e.companies[data.companyId].title : '',
    device: data.deviceId ? e.devices[data.deviceId].description : '',
    tariffOption: data.tariffId ? e.tariffs.byId[data.tariffId].name : '',
    features: data.deviceId ? e.devices[data.deviceId].features || [] : [],
    size: sizes(state)[0],
    ...pick(data, [
      'orientation',
      'cuttingMarks',
      'colors',
      'price',
      'oldPrice',
      'priceNotarif',
      'promo'
    ])
  }
}

export function models (state: RootState) {
  const { companyId, categoryId } = form(state)
  const { companies, devices } = entities(state)

  return (companies[companyId || 'not-yet']?.devices || []).filter(id => {
    const device = devices[id]
    if (!device || !device.companyId) return console.warn('bad device', device)
    const match = device.categoryId === categoryId
    match && console.log(`${device.modelname} of ${companies[device.companyId].title} ${match}`)
    return match
  })
}

export function tariffs (state: RootState) {
  const { tariffId, tariffOption, categoryId } = form(state)
  const { tariffsOptions: tariffso, tariffs } = entities(state)
  let val = [] as any[]
  let val2 = []as any[]

  if (categoryId) {
    try {
      val = tariffs.byCategory[categoryId].map(id => pick(tariffs.byId[id], ['id', 'name']))
    } catch (error) {
      val = []
    }
    try {
      val2 = tariffso.byCategory[categoryId].map(id => pick(tariffso.byId[id], ['id', 'name']))
    } catch (error) {
      val2 = []
    }
  } else {
    val = []
    val2 = []
  }

  return [{ tariffId, tariffOption }, val, val2] as any[]
}

export function errors (state: RootState, filter?:keyof RootState['pricingTool']['form'] | 'sizes') {
  const es = slice(state).error
  return filter ? Array.from(es || []).filter(r => r === filter).length > 0 : es
}

export interface PreviewState{
    orientation?: string;
    cuttingMarks?: string;
    colors?: string;
    category?: string;
    company?: string;
    device?: string;
    features: string[];
    tariffId?: string;
    tariffOption?: string;
    price?: string;
    oldPrice?: string;
    priceNotarif?: string;
    promo?: string;
    size: Size;
}
