import React, { useState } from 'react'

import { AppFooter, AppHeader, DefaultLayout } from '@smart/design-system'
import './index.css'
import styled from 'styled-components'

import Preview from './Components/Preview'
import PricingForm from './Components/PricingForm'
import PricingSidebar from './Components/Sidebar'

import '@smart/design-system/src/styles/global.css'

interface Page{
 'slug': string;
 'name':string;
}

function PricingTool () {
  const [pages, setPages] = useState<Page[]>([])
  React.useEffect(() => {
    fetch(process.env.REACT_APP_BACKEND_HOST + '/api/v1/pages').then(r => r.json()).catch((e) => {
      console.error(e)
      return []
    }).then(pages => {
      setPages(pages)
    })
  }, [])
  return (
    <DefaultLayout maxWidth={'1200px'} id="pricetag" className="main container">
      <AppHeader subheader="SMART PORTAL" />

      <>
        <h5 className="text-muted pt-3 pb-4 d-none d-lg-block">
          <div className="breadcrumb">
            <span>{'Startseite'}</span>
            <span>{'Auswahl'}</span>
            <span>{'Preisschild'}</span>
          </div>
        </h5>

        <Row style={{ paddingTop: '1em' }}>
          <PricingSidebar />

          <PricingForm />

          <Preview />
        </Row>
      </>

      <AppFooter pages={pages} host={process.env.REACT_APP_BACKEND_HOST} />
    </DefaultLayout>)
}

export default PricingTool

const Row = styled.div`
display:flex;
flex-flow: row nowrap;
> aside{
  max-width:350px;;
}
> section, > div{
  min-width: 50%;
  flex:1;
  padding:0 2rem;
}
@media (max-width: 768px) {
  > aside{
    max-width: 100%;
  }
  flex-direction: column;
  padding: 0 1rem;
  > aside, > section{
    width: 100%;
  }
  > aside ol{
    display: flex;
    flex-flow: row nowrap;
    overflow-x: auto;
  }

  > aside ol > li{
    margin: 0 1em;
    min-width: 33%;
  }
}
`
