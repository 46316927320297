type RequestParamType = {
  [key: string]: any;
};

export const getUriWithParam = (
  params: RequestParamType,
  baseUrl = window.location.href
): string => {
  const Url = new URL(baseUrl)
  const urlParams: URLSearchParams = new URLSearchParams(Url.search)
  for (const key in params) {
    if (params[key] !== undefined) {
      urlParams.set(key, params[key])
    }
  }
  Url.search = urlParams.toString()
  return Url.toString()
}
