import React, { ErrorInfo, HTMLAttributes, ReactNode } from 'react'

export interface ErrorBoundaryState{
  error: null | Error;
}

export interface ErrorBoundaryProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode | ReactNode[];
  fallback: (e:ErrorBoundaryState)=>ReactNode
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state = { error: null };

  static getDerivedStateFromError (error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error }
  }

  componentDidCatch (error: Error, errorInfo: ErrorInfo) {
    console.error(error, errorInfo)
  }

  render () {
    if (this.state.error !== null) {
      // You can render any custom fallback UI
      return this.props.fallback(this.state)
    }

    return this.props.children
  }
}
