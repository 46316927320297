import React, { FC, HTMLAttributes, ReactNode } from 'react'

import t from 'prop-types'
import styled from 'styled-components'

export interface GridProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  /**
   * width of item
   */
  itemWidth?: string,
  /**
   * count of items per row (only if itemWidth is not defined)
   */
  count?: number[];
  /**
   * Row height
   */
  itemHeight?: string,
  /**
   * gap between tiles
   */
  gap?: string,
  /**
   * make height of item = its width
  */
  square?:boolean;
  /**
   * array of breakpoints for responsive design
   */
  breakPoints?: string[]
}

function gridShape ({ count, breakPoints = [], square, itemWidth, itemHeight }:GridProps) {
  const r:Record<string, any> = { }

  if (itemWidth) {
    r.gridTemplateColumns = `repeat(auto-fit,minmax(${itemWidth},1fr))`
  } else {
    if (count) {
      r.gridTemplateColumns = `repeat(${count[0]},minmax(0,1fr))`
      count.slice(1).forEach((c, idx) => {
        r[`@media( min-width: ${breakPoints[idx] || ((350 * (idx + 1)) + 'px')} )`] = {
          gridTemplateColumns: `repeat(${c},minmax(0,1fr))`
        }
      })
    }
  }

  if (itemHeight) {
    r.gridTemplateRows = `repeat(auto-fit,minmax(${itemHeight},1fr))`
  }

  return r
}

export const Grid = styled.div<GridProps>`
  margin: 0;
  padding: 0;
  max-width: var(--max-width, 800px);
  display: grid;
  grid-gap: ${({ gap }) => gap};
  ${gridShape}
  ${({ square }) => square && `
  > * {
    padding: 1rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  > *::after {
    content: "";
    display: block;
    padding-bottom: 100%;
    padding-bottom: calc(100% - 2rem);
  }
  > * > img {
    position: absolute;
    max-width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }`}
`

Grid.propTypes = {
  itemWidth: t.string.isRequired,
  gap: t.string.isRequired,
  breakPoints: t.arrayOf(t.string.isRequired).isRequired,
  children: t.node,
  square: t.bool,
  itemHeight: t.string
}

Grid.defaultProps = {
  itemWidth: '250px',
  gap: '1em',
  breakPoints: ['370px', '600px', '800px', '1200px']
}
