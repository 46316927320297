import styled from 'styled-components'

export const Container = styled.div<{ maxWidth?: string }>`
  --max-width: ${(props) => props.maxWidth || '1200px'};
`
export const Content = styled.main`
  min-height: calc(100vh - 160px);
  max-width: var(--max-width, 1200px);
  margin: 0 auto;
  overflow-x:hidden;
  overflow-y:auto;
`
