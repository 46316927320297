import { combineReducers } from '@reduxjs/toolkit'
import { getUriWithParam } from '@smart/design-system'

import ptSlice from './pricingTool/reducer'

const STORAGE_ADDR = process.env.storage_keyname || 'ADDR'

const rootReducer = combineReducers({
  [ptSlice.name]: ptSlice.reducer
})

const presistedRootReducer :typeof rootReducer = (state, action) => {
  if (action.type === '@@RESET') {
    sessionStorage.clear()
    const newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname
    window.history.pushState({ path: newUrl }, '', newUrl)
    return rootReducer(undefined, action)
  }

  if (action.type === '@@INIT') {
    const json = sessionStorage.getItem(STORAGE_ADDR)
    state = json !== null ? JSON.parse(json) : state
    const searchParams = Array.from(new URLSearchParams(window.location.search))

    // Iterate the search parameters.
    for (const [key, val] of searchParams) {
      if (state && key === 'step') {
        state[ptSlice.name].step = Number(val)
      } else {
        // @ts-ignore
        if (state)state[ptSlice.name].form[key] = val
      }
    }
  }
  const newState = rootReducer(state, action)
  if (state !== newState) {
    sessionStorage.setItem(STORAGE_ADDR, JSON.stringify(newState))
    const newUrl = (getUriWithParam({ step: newState[ptSlice.name].step, ...newState[ptSlice.name].form }))
    window.history.pushState({ path: newUrl }, '', newUrl)
  }

  return newState
}

export default presistedRootReducer
