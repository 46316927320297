import React from 'react'

import { Button, scrollToTop } from '@smart/design-system'
import { connect } from 'react-redux'

import { de } from '../i18n'
import Steps from '../steps'
import { AppDispatch, RootState } from '../store'
import { ptActions, ptSelectors } from '../store/pricingTool'
import { DownLoad } from './DownloadBtn'

const f = { } as any
f.float = 'right'

const PricingForm = ({ setStep, step, loadData, reset }:IPricingForm) => {
  const Component = Steps[step]
  React.useEffect(() => {
    scrollToTop(document.body)
    if (!Component) {
      reset()
    }
  }, [step, Component, reset])
  React.useEffect(() => {
    console.debug(`loading form data from ${process.env.REACT_APP_BACKEND_HOST}...`)
    loadData()
  }, [loadData])

  return (
    <div>
      {Component && <Component />}

      <div style={{ padding: '1rem 1rem 0 2rem' }}>
        <Button
          flat
          primary
          type="button"
          disabled={!Steps[step - 1]}
          onClick={() => setStep(step - 1)}>
          {de.back}
        </Button>
        {step === 3
          ? (
            <DownLoad />
          )
          : (
            <Button
              style={f}
              primary
              type="button"
              disabled={!Steps[step + 1]}
              onClick={() => setStep(step + 1)}>
              {de.next}
            </Button>
          )}
      </div>
    </div>
  )
}

const mapStateToProps = (state:RootState) => ({
  step: ptSelectors.step(state)
})

const mapDispatchToProps = (dispatch:AppDispatch) => ({
  setStep: (payload:number) => dispatch(ptActions.movePage(payload)),
  loadData: () => dispatch(ptActions.loadData()),
  reset: () => dispatch({ type: '@@RESET' })
})

const PricingFormContainer = connect(mapStateToProps, mapDispatchToProps)(PricingForm)
export default PricingFormContainer

type IPricingForm = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
