import React, { FC, HTMLAttributes, ReactNode } from 'react'

import t from 'prop-types'
import styled from 'styled-components'

export interface FlexGridProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  /**
   * breakpoint for responsive design
   */
  breakPoint?: string
  cols?: number[] | string[] | Array<string|number>
}

export const FlexGrid = styled.div<FlexGridProps>`
  margin: 0;
  padding: 0;
  clear: both;
  max-width: var(--max-width, 800px);
  overflow-x:hidden;
  overflow-y:auto;
  display: flex;

  > section, > div {
    flex: ${({ cols }) => cols ? cols[1] || 3 : 3};
  }

  > aside {
    flex: ${({ cols }) => cols ? cols[0] || 1 : 1};
  }

  @media (max-width: ${props => props.breakPoint || '768px'}) {
    flex-direction: column;
    padding: 0 1rem;
  }
`

FlexGrid.propTypes = {
  breakPoint: t.string,
  children: t.node.isRequired
}

FlexGrid.defaultProps = {
  breakPoint: '768px'
}

export const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1;
`

export const Col = styled.div`
display: flex;
flex-flow: column nowrap;
flex: 1 1;
`
