import React, { FC } from 'react'

import { Col } from '@smart/design-system'
import { connect } from 'react-redux'

import { de } from '../i18n'
import { AppDispatch, RootState } from '../store'
import { ptActions, ptSelectors, SliceState } from '../store/pricingTool'

const Step3:FC<IStep3> = ({ colors, cuttingMarks, logo, setInputValue }) => {
  return (
    <section style={{ margin: '-.5rem 1rem 0 2rem' }}>
      <h1>{de.form.page3}: </h1>
      <Col>
        <div>

          <select onChange={(e) => setInputValue({ colors: e.target.value })} value={colors} name="colors" className="select">
            <option value="0">Schwarz-weiß</option>
            <option value="1">Farbe</option>
          </select>

          <select
            onChange={(e) => setInputValue({ cuttingMarks: e.target.value })}
            value={cuttingMarks || '1'}
            name="cuttingMarks"
            className="select">
            <option value="0">Ohne Schnittmarken drucken</option>
            <option value="1">{de.mit}</option>
          </select>
        </div>
      </Col>
    </section>
  )
}

const mapStateToProps = (state:RootState) => {
  const { colors, cuttingMarks, logo } = ptSelectors.form(state)
  return { colors, cuttingMarks, logo }
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setInputValue: (payload: Partial<SliceState['form']>) => dispatch(ptActions.inputChange(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Step3)

type IStep3 = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
