import { KeyboardEventHandler } from 'react'

import color from 'color'

export function ifEnter (fn: KeyboardEventHandler): KeyboardEventHandler {
  return (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      fn(e)
    }
  }
}

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const urlRegex = /(https?:\/\/)((?:www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,16}\b)([-a-zA-Z0-9@:%_+.~#?&//=]*)/

export const keycodes = {
  enter: 13,
  escape: 27,
  spacebar: 32,
  leftArrow: 37,
  upArrow: 38,
  rightArrow: 39,
  downArrow: 40
}

/**
 * Convert a hex value into RGB
 * @param  {String} hex    Hexidecimal color value to change
 * @return {String}        RGB values
 */
export function rgb (hex: string) {
  return color(hex).rgb().array().join(',')
}

/**
 * Return an rgba string value for CSS
 * @param  {String} hex     Hexidecimal color value
 * @param  {Number} opacity Number value between 0 and 1
 * @return {String}         RGBA string
 */
export function rgba (hex: string, opacity: number) {
  return color(hex).alpha(opacity).rgb().string()
}

/**
 * Replicate Sass's darken function
 * @param  {String} hex    Hexidecimal color value to modify
 * @param  {Number} amount Amount to lighten, between 1 and 100
 * @return {String}        New hexidecimal color value
 */
export function darken (hex: string, amount: number) {
  return color(hex).darken((amount / 100)).hex()
}

/**
 * Replicate Sass's lighten function
 * @param  {String} hex    Hexidecimal color value to modify
 * @param  {Number} amount Amount to lighten, between 1 and 100
 * @return {String}        New hexidecimal color value
 */
export function lighten (hex: string, amount: number) {
  return color(hex).lighten((amount / 100)).hex()
}

export function supportsSmoothScrolling () {
  const body = document.body
  const scrollSave = body.style.scrollBehavior
  body.style.scrollBehavior = 'smooth'
  const hasSmooth = getComputedStyle(body).scrollBehavior === 'smooth'
  body.style.scrollBehavior = scrollSave
  return hasSmooth
};

export function SmoothVerticalScrolling (element:HTMLElement, time = 300) {
  const eTop = element.getBoundingClientRect().top
  const eAmt = eTop / 100
  let curTime = 0
  while (curTime <= time) {
    window.setTimeout(() => window.scrollBy(0, eAmt), curTime)
    curTime += time / 100
  }
}

export function scrollToTop (elm:HTMLElement, time = 300) {
  if (supportsSmoothScrolling()) {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  } else {
    SmoothVerticalScrolling(elm, time)
  }
}

export function unitToPx (unit:string):number {
  if (typeof document !== 'undefined') {
    const div = document.createElement('div')
    div.style.display = 'block'
    div.style.height = unit
    document.body.appendChild(div)
    const px = parseFloat(window.getComputedStyle(div, null).height)
    div?.parentNode?.removeChild(div)

    return px
  }
  console.error('call to unit to px work only in browser enviroment. call to unitToPx(', unit)
  return Number(unit.replace(/[^0-9.]/g, '')) * 3.779527559055
}

export function scaleUnitToPx (mm:string, scaleTo:number) {
  const px = unitToPx(mm)
  return parseFloat(((scaleTo === 0 ? px : scaleTo) / px).toFixed(2).replace('.00', ''))
}

export function grayScaleCanvas (context:any, canvas:any) {
  const imgData = context.getImageData(0, 0, canvas.width, canvas.height)
  const pixels = imgData.data
  for (let i = 0, n = pixels.length; i < n; i += 4) {
    const grayscale = pixels[i] * 0.3 + pixels[i + 1] * 0.59 + pixels[i + 2] * 0.11
    pixels[i] = grayscale // red
    pixels[i + 1] = grayscale // green
    pixels[i + 2] = grayscale // blue
    // pixels[i+3]              is alpha
  }
  // redraw the image in black & white
  context.putImageData(imgData, 0, 0)
}

export function preloadImages (array:string[], storage:Storage) {
  const { list } = preloadImages
  const items = storage ? array.filter((i) => storage.getItem(i) !== null) : array
  for (let i = 0; i < items.length; i++) {
    const img = new Image()
    const canvas = document.createElement('canvas')
    img.onload = function () {
      if (list.has(items[i])) {
        list.delete(items[i]) // free memory once image loaded,
      }
      if (!storage) return
      // saving to localStorage
      canvas.width = img.width
      canvas.height = img.height

      const ctx = canvas.getContext('2d')
      if (ctx) {
        ctx.drawImage(img, 0, 0)
        const dataUrl = canvas.toDataURL('image/png')
        storage.setItem(items[i], dataUrl)
      }
    }
    list.add(array[i])
    img.src = array[i]
  }
}
preloadImages.list = new Set<string>()
