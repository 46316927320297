import React, { FC, HTMLAttributes, ReactNode, useRef } from 'react'

import t from 'prop-types'
import styled from 'styled-components'

export interface TextInputProps extends HTMLAttributes<HTMLInputElement> {
  /**
   * function called to change value
   */
  onChange?: (e:any)=>void;
  /**
   * input value
   */
  value: string;
  /**
   * input name
   */
  name: string;
  /**
   * placeholder text to show while input is empty
   */
  placeholder?: string;
  /**
   * text to show above input as floating label
   */
  label?: string;
  /**
   * override input state style
   */
  mode?: 'error'|'success'|'info'|'default',
  /**
   * error message to show
   */
  error?: string,
  /**
   * information message to show
   */
  info?: string,
  /**
   * success message to show
   */
  success?: string,
  /**
   * extra information in black
   */
  message?:string;
}

export const TextInput: FC<TextInputProps> = ({ name, message, label, placeholder, mode, info, success, error, ...props }) => {
  const { current } = useRef(`${name}${Date.now()}`)
  const currentMode = mode || (error ? 'error' : info ? 'info' : success ? 'success' : 'primary')

  return (
    <Label mode={currentMode} data-testid="textinput" htmlFor={current}>
      <Input placeholder={label ? '' : placeholder} name={name} id={props.id || current} type="string" {...props} />
      {label && <span>{label}</span>}
      {error
        ? <Feedback mode="error">{error}</Feedback>
        : success
          ? <Feedback mode="success">{success}</Feedback>
          : info
            ? <Feedback mode="info">{info}</Feedback>
            : message
              ? <Feedback>{message}</Feedback>
              : null}
    </Label>
  )
}

TextInput.defaultProps = {
  value: '',
  placeholder: '',
  name: 'text-input' + Date.now()
}

TextInput.propTypes = {
  name: t.string.isRequired,
  value: t.string.isRequired,
  placeholder: t.string,
  error: t.string,
  info: t.string,
  success: t.string,
  label: t.string
}

type P = {mode?: 'error'|'success'|'info'|'primary'|'default'}

const map = {
  error: 'danger',
  info: 'info',
  success: 'success',
  default: 'gray-250',
  primary: 'primary'
}

const Input = styled.input<P>`
  display: block;
  width: 100%;
  padding: .51em 2em .5em .5em;
  border-radius: 4px;
  border: 1px solid;
  outline: none;
`

const Feedback = styled.small<P>`
  color: #222;
`

const Label = styled.label<P>`
  display: inline-block;
  margin-bottom: 1em;
  border: none;
  outline: none;
  color: var(--${({ mode }) => mode ? map[mode] : 'primary'});
  padding-top: 2rem;
  position: relative;
  > span{
    transition: all .2s ease;
  }
  > span + small {
    position: absolute;
    left: .51rem;
    bottom: 0;
  }
  > input + span{
    position: relative;
    top: -2rem;
    left: 1rem;
  }
  > input:focus + span{
    position: relative;
    top: -4rem;
    left: .5rem;
  }
  ${Input}{
    border-color: var(--${({ mode }) => mode && map[mode] ? map[mode] : map.default});
  }
  ${Input}:focus{
    border-color: var(--primary);
  }
  ${Feedback}{
    color: var(--${({ mode }) => mode ? map[mode] : 'inherit'});
  }
`
