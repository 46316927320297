import React from 'react'

import { TelecomSelect } from '@smart/design-system'
import { useSelector } from 'react-redux'

import { useAppDispatch } from '../store'
import { ptActions, ptSelectors } from '../store/pricingTool'

function TariffSelection () {
  const dispatch = useAppDispatch()
  const [{ tariffId, tariffOption }, val, val2] = useSelector(ptSelectors.tariffs)

  return (
    <div style={{ fontSize: 12 }}>
      <TelecomSelect
        placeholder={'Ohne Tarif erstellen'}
        value={`${tariffId}`}
        onChange={(e) =>
          dispatch(ptActions.inputChange({ tariffId: e.target.value/*, tariffOption: '' */ }))
        }
        values={val}
      >{(row) => <option key={row.id} value={row.id}>{row.name}</option>}</TelecomSelect>

      <TelecomSelect
        placeholder={'Tarifoption auswählen'}
        value={`${tariffOption}`}
        onChange={(e) => dispatch(ptActions.inputChange({ tariffOption: e.target.value }))}
        values={val2}
      >{(row) => <option key={row.id} value={row.id}>{row.name}</option>}</TelecomSelect>

    </div>
  )
}
export default React.memo(TariffSelection)
