export const de = {
  Formate: 'Formate (Mehrauswahl möglich)',
  back: 'Zurück',
  download: 'PDF Herunterladen',
  farboption: 'Farboption auswählen',
  form: {
    hochformat: 'Hochformat',
    page1: 'Wählen Sie das Format',
    page2: 'Bitte geben Sie folgende Informationen an',
    page3: 'Wählen Sie die bevorzugte Druck-Option aus',
    page4: 'Übersicht aller angegebenen Daten',
    querformat: 'Querformat'
  },
  landscape: 'Landschaft',
  mit: 'Mit Schnittmarken drucken',
  next: 'Weiter',
  optional: 'optional',
  portrait: 'Porträt',
  preis: 'Preis/ Aktionspreis',
  preis_holder: 'Betrag eingeben (z.B. 100€)',
  preis_vertrag: 'Preis ohne Vertrag',
  preview: {
    Farb: 'Farbausgabe Druck',
    Tarif: 'Tarif',
    Tarifoption: 'Tarifoption',
    geratetyp: 'Gerätetyp',
    hersteller: 'Hersteller',
    logo: 'Logo anzeigen',
    modell: 'Modell',
    schnittmarken: 'Schnittmarken drucken',
    seitenausrichtung: 'Seitenausrichtung',
    seitenformat: 'Seitenformat',
    storer_darstell: 'Störer Darstellungsoption'
  },
  sidebar: {
    Formate: 'Formate',
    ausrichtung: 'Ausrichtung',
    druck: 'Druck Optionen',
    gerateinfo: 'Geräteinformationen',
    informationen: 'Informationen',
    preiskommunikation: 'Preiskommunikation',
    preisschilder: 'Preisschilderstellung',
    preisschilderstellung: 'Preisschilderstellung',
    seitenverhaltnis: 'Seitenverhältnis',
    tarifinfo: 'Tarifinformationen',
    zusammenfassung: 'Zusammenfassung',
    zusatz: 'Zusatz'
  },
  streichpreis: 'Streichpreis',
  vorschau: 'Vorschau'
}
export const en = de
