import { connect } from 'react-redux'
import styled from 'styled-components'

import { AppDispatch, RootState } from '../store'
import { ptActions, ptSelectors } from '../store/pricingTool'

type IProp = {of:keyof RootState['pricingTool']['form'] | 'sizes'}
type IError = Record<keyof RootState['pricingTool']['form'] | 'sizes', string>;

const errors:Partial<IError> = {
  sizes: 'Bitte wählen sie mindestens ein Papierformat aus',
  deviceId: 'Bitte wählen Sie ein Endgerät aus',
  price: '"Preis/ Aktionspreis" ist ein Pflichtfeld',
  oldPrice: '"Streichpreis (optional)" ist ein Pflichtfeld',
  priceNotarif: 'Preis ohne Vertrag ist ein Pflichtfeld'
}

function Warning ({ of, error, removeError }:IProps) {
  if (!error) return null
  return <Message>
    {errors[of] || of + ' ist ein Pflichtfeld'}

    <button onClick={() => removeError(of)}>
      × Nachricht schließen
    </button>
  </Message>
}

const Message = styled.small`
display: block;
width: 100%;
clear: both;
border: 1px solid;
font-size: 1.3rem;
line-height: 1.5rem;
margin: 10px 0px;
padding: .5em 1em;
color: #721c24;
background-color: #f8d7da;
border-color: #f5c6cb;
> button {
  float: right;
  color: inherit;
  font-weight: 900;
}
`

const mapStateToProps = (state:RootState, props:IProp) => {
  return { error: ptSelectors.errors(state, props.of) }
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  removeError: (name:string) => dispatch(ptActions.removeError(name))
})

export default connect(mapStateToProps, mapDispatchToProps)(Warning)

type IProps = IProp & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
