import React, { FC, ReactNode, SyntheticEvent } from 'react'

import t from 'prop-types'
import styled from 'styled-components'

import { Grid, GridProps } from '../Grid'

export interface SelectableTilesProps {
  tiles?: {id: string; element: ReactNode}[];
  multiple?:boolean;
  onChange: (n:{id: string}, e: SyntheticEvent)=>void;
  value: string|string[];
  gridProps?: GridProps
}

export const SelectableTiles: FC<SelectableTilesProps> = ({ tiles, multiple, onChange, value, gridProps = {}, ...props }) => {
  return (
    <Grid data-testid="selectabletiles" {...gridProps}>
      {tiles?.map(({ id, element }, idx) => <Selectable
        key={id}
        type="button"
        style={gridProps ? { height: gridProps.itemHeight } : {}}
        onClick={(e) => onChange && onChange(tiles[idx], e)}
        active={typeof value === 'string' ? id === value : value.indexOf(id) > -1}
        {...props}
      >
        {element}
      </Selectable>)}
    </Grid>
  )
}

SelectableTiles.propTypes = {
  tiles: t.arrayOf(
    t.shape({ id: t.string.isRequired, element: t.node.isRequired }).isRequired
  ).isRequired,
  multiple: t.bool,
  onChange: t.func.isRequired,
  value: t.oneOfType([t.string, t.arrayOf(t.string.isRequired)]).isRequired
}

SelectableTiles.defaultProps = {
  tiles: [],
  value: ''
}

const Selectable = styled.button<{active?: boolean}>`
  border: 1px solid;
  border-color: ${({ active }) => active ? 'var(--magenta, red)' : 'transparent'};
  outline: none;
  padding: 1em;
`
