import * as z from 'zod'

import { RootState } from './../../store'
import { form, slice } from './selectors'

export const Step1Schema = z.object({
  orientation: z.union([z.literal('1'), z.literal('0')]),
  sizes: z.string().array().min(1)
})

export const Step2Schema = z.object({
  companyId: z.string().uuid(),
  deviceId: z.string().uuid(),
  categoryId: z.string().uuid(),
  tariffId: z.string().optional(),
  tariffOption: z.string().optional(),
  promo: z.string().optional(),
  oldPrice: z.union([z.string(), z.number()]).optional(),
  price: z.union([z.string(), z.number()]),
  priceNotarif: z.union([z.string(), z.number()])
})

export const validate = [
  (state) => {
    const { orientation } = form(state)
    const sizes = slice(state)
      .sizes.filter((r) => r.selected)
      .map((r) => r.id)

    return Step1Schema.safeParse({ orientation, sizes })
  },
  (state) => {
    const data = form(state)
    return Step2Schema
      .passthrough()
      .safeParse(data)
  },
  (state) => {
    const data = form(state)
    return z
      .object({
        colors: z.union([z.literal('1'), z.literal('0')]),
        cuttingMarks: z.union([z.literal('1'), z.literal('0')])
      })
      .passthrough()
      .safeParse(data)
  }
] as Array< (state:RootState)=>any >
