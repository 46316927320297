import { createSlice, PayloadAction } from '@reduxjs/toolkit'
/* eslint-disable camelcase */

export type Size = {
  id: 'A5' | 'A4' | 'A6' | 'A7'| 'A8'| '50',
  w: number;
  h: number;
  unit: 'mm' | 'px' | 'pt' | 'cm' | 'em' | 'in' | 'ex' | 'pc';
  selected: boolean;
}

export interface PromoLogo {
  id: string;
  name: string;
  image_description: string;
  image_url: string;
  order: string;
  created_at: string | Date;// Date;
  updated_at: string | Date;// Date;
  static?: boolean
}

export interface SliceState{
  updatedAt: string;
    form: {
        orientation: '0' | '1';
        cuttingMarks: string;
        colors: string;
        price?: string;
        oldPrice?: string;
        priceNotarif?: string;
        logo?: string;
        promo?: string;
        categoryId?: string;
        companyId?: string;
        deviceId?: string;
        tariffId ?: string;
        tariffOption?: string;
    };
    step: number;
    sizes: Size[],
    promoLogos: PromoLogo[],
    entities: Entities,
    error: any
}

export interface Device{
  id: string;
  modelname: string;
  description: string;
  subline: string;
  price: string;
  color: string;
  material: string;
  ean: string;
  sales_start_at: string;
  created_at: string;
  updated_at: string;
  features: string[];
  categoryId: string;
  companyId: string;
  /** ignore it ! Its currently always empty */
  data?: any[];
  device_category: DeviceCategory;
  device_manufacture: DeviceCategory;
  url: string;
}
export interface DeviceCategory{
  id: string;
  title: string;
  created_at: string;
  updated_at: string;
}
export interface TariffOption{
  'id': string,
  'name': string,
  'category': 'telefonie',
  'category_option': 'handys',
  'device_category': {
    id: string,
    title: string,
    created_at: string,
    updated_at: string
  }
  'paid_type': string,
  'price_unit': string,
  'subline'?: string,
  'min_contract_term'?: string,
  'is_active': boolean;
}
export interface Tariff{
  'id': string,
  'name': string,
  'category': 'handys',
  'device_category': {
    id: string,
    title: string,
    created_at: string,
    updated_at: string
  }
  'price': string,
  'provision_price': string,
  'min_contract_term'?: string,
  'subtitle'?: string,
  'phone_flat': boolean,
  'sms_flat': boolean,
  'lte_inclusive': boolean,
  'is_active': boolean
}

export interface TariffApi{
  tariffs: Tariff[],
  tariff_options: TariffOption[]
}
export interface Types{
  'id': string,
  'label': string,
  'companies': string[]
}

export interface Entities{
  types: Record<string, DeviceCategory & {companies: string[]}>,
  devices: Record<string, Partial<Device>>,
  companies: Record<string, DeviceCategory & {devices: string[]}>,
  tariffs: {
    byId: Record<string, Partial<Tariff>>,
    byCategory: Record<string, string[]>,
  },
  tariffsOptions:{
    byId: Record<string, Partial<TariffOption>>,
    byCategory: Record<string, string[]>,
  },
}

// initialstate
export const initialState: SliceState = {
  updatedAt: '1960-01-01',
  form: {
    orientation: '0',
    cuttingMarks: '0',
    colors: '1'
  },
  step: 0,
  promoLogos: [
    {
      id: '525c77bc-59ab-4d4d-9de7-d868a8593d26',
      name: 'Stoerer NEU',
      image_description: '',
      image_url: require('../../imgs/neu.png').default,
      order: '',
      created_at: '2020-12-24T18:53:24.468Z',
      updated_at: '2020-12-24T18:53:24.472Z',
      static: true
    },
    {
      id: '525c77bc-59ab-4d4d-9de7-aktion',
      name: 'Stoerer aktion',
      image_description: '',
      image_url: require('../../imgs/aktion.png').default,
      order: '',
      created_at: '2020-12-24T18:53:24.468Z',
      updated_at: '2020-12-24T18:53:24.472Z',
      static: true
    }
  ],
  sizes: [
    { id: 'A5', w: 148, h: 210, unit: 'mm', selected: false },
    { id: 'A4', w: 210, h: 297, unit: 'mm', selected: false },
    { id: 'A6', w: 105, h: 148, unit: 'mm', selected: false },
    { id: 'A7', w: 74, h: 105, unit: 'mm', selected: false },
    { id: 'A8', w: 52, h: 74, unit: 'mm', selected: false },
    { id: '50', w: 50, h: 30, unit: 'mm', selected: false }
  ],
  entities: {
    types: {},
    devices: {},
    companies: {},
    tariffs: {
      byId: {},
      byCategory: {}
    },
    tariffsOptions: {
      byId: {},
      byCategory: {}
    }
  },
  error: null
}

export const ptSlice = createSlice({
  name: 'pricingTool',
  initialState,
  reducers: {
    toggleSize (state, { payload }: PayloadAction<string>) {
      state.sizes = state.sizes.map((row) => {
        return `${row.id}` === `${payload}` ? { ...row, selected: !row.selected } : row
      })
    },
    resetPromoLogos (state, { payload }: PayloadAction<PromoLogo[]>) {
      if (payload.length) {
        return {
          ...state,
          promoLogos: state.promoLogos.filter((obj) => !obj || !obj.image_url || state.promoLogos.some(i => i.id === obj.id && i.updated_at === obj.updated_at) === false).concat(payload)
        }
      }
      return state
    },
    resetDevices (state, { payload }: PayloadAction<Device[]>) {
      return payload.reduce((carry, item) => {
        const { device_category: deviceCategory, device_manufacture: deviceManufacture, ...rest } = item
        if (!deviceCategory || !deviceManufacture) {
          // ignore this devices... It has no category
          return carry
        }
        rest.companyId = deviceManufacture.id
        rest.categoryId = deviceCategory.id
        carry.entities.devices[item.id] = rest
        // const itemUpdatedAt = Number(`${item.updatedAt.split('T')[0]}`.replace(/-/g, ''));
        // current api return iso 8601 date;
        // which can be directly compared by js > sign

        // if (item.updated_at > state.updatedAt) {
        //   carry.updatedAt = item.updated_at
        //   // carry.updatedAt =
        //   //   carry.updatedAt.slice(0, 4) +
        //   //   '-' +
        //   //   carry.updatedAt.slice(4, 6) +
        //   //   '-' +
        //   //   carry.updatedAt.slice(-2);
        // }

        if (!carry.entities.companies[deviceManufacture.id]) {
          carry.entities.companies[deviceManufacture.id] = {
            ...deviceManufacture,
            devices: [item.id]
          }
        } else {
          if (carry.entities.companies[deviceManufacture.id].devices.indexOf(item.id) === -1) {
            carry.entities.companies[deviceManufacture.id].devices.push(item.id)
          }
        }

        if (!carry.entities.types[deviceCategory.id]) {
          carry.entities.types[deviceCategory.id] = {
            ...deviceCategory,
            companies: [deviceManufacture.id]
          }
        } else {
          if (
            carry.entities.types[deviceCategory.id].companies.indexOf(deviceManufacture.id) === -1
          ) {
            carry.entities.types[deviceCategory.id].companies.push(deviceManufacture.id)
          }
        }

        return carry
      }, state)
    },
    resetTariffs (state, { payload }: PayloadAction<TariffApi>) {
      payload.tariffs.forEach(row => {
        state.entities.tariffs.byId[row.id] = row
        state.entities.tariffs.byCategory[row.device_category?.id] = state.entities.tariffs.byCategory[row.device_category?.id] || []
        if (state.entities.tariffs.byCategory[row.device_category?.id].indexOf(row.id) === -1) {
          state.entities.tariffs.byCategory[row.device_category?.id].push(row.id)
        }
      })
      payload.tariff_options.forEach(row => {
        state.entities.tariffsOptions.byId[row.id] = row
        state.entities.tariffsOptions.byCategory[row.device_category?.id] = state.entities.tariffsOptions.byCategory[row.device_category?.id] || []
        if (state.entities.tariffsOptions.byCategory[row.device_category?.id].indexOf(row.id) === -1) {
          state.entities.tariffsOptions.byCategory[row.device_category?.id].push(row.id)
        }
      })
    },
    setStep (state, { payload }:PayloadAction<number>) {
      state.step = payload
      // clear errors if page change
      state.error = []
    },
    inputChange (state, { payload }: PayloadAction<Partial<SliceState['form']>>) {
      return {
        ...state,
        form: {
          ...state.form,
          ...payload
        }
      }
    },
    hasError (state, { payload }:PayloadAction<{code:string;message:string;path:string[]}[]>) {
      state.error = payload.map(error => error.path).flat()
    },
    removeError (state, { payload }: PayloadAction<string>) {
      state.error = Array.from(state.error || []).filter(e => e !== payload)
    }
  }
})

export default ptSlice
