import { createGlobalStyle } from 'styled-components'
import 'normalize.css'
const GlobalStyle = createGlobalStyle`
*, *::after, *::before {
    box-sizing: border-box;
    font-family: TeleNeoWeb, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

:root {
    --white: #fff;
    --gray-100: #fafafa;
    --gray-200: #ededed;
    --gray-230: #e6e6e6;
    --gray-250: #dcdcdc;
    --gray-300: #d0d0d0;
    --gray-350: #b2b2b2;
    --gray-400: #a4a4a4;
    --gray-500: #7c7c7c;
    --gray-600: #6c6c6c;
    --gray-700: #4b4b4b;
    --gray-800: #383838;
    --gray-900: #262626;

    --black: #000;
    --blue: #00739f;
    --light_blue: #80dcff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --light_red: #ed8b8b;
    --red: #d90000;
    --orange: #fd7e14;
    --yellow: #ff9a1e;
    --light_green: #bbdc9b;
    --green: #558f1c;
    --teal: #20c997;
    --cyan: #00a0de;
    --magenta_light: #f7bedb;
    --magenta: #e20074;
    --magenta_dark: #bd0460;
    --magenta_darker: #a30051;

    --facebook: #1472fb;
    --twitter: #1da1f2;
    --whatsapp: #7bb32e;

    --primary: var(--magenta);
    --secondary: var(--gray-200);
    --success: var(--green);
    --info: var(--cyan);
    --warning: var(--yellow);
    --danger: var(--red);
    --light: var(--gray-100);
    --dark: var(--gray-700);
}

#root, html, body{
    padding: 0;
    margin: 0;
    font-family: TeleNeoWeb, -apple-system, system-ui, "Segoe UI";
    font-size: 12px;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}
`

export default GlobalStyle
