import * as actions from './actions'
import ptSlice from './reducer'
export * from './reducer'
export * as ptSelectors from './selectors'
export * from './validate'

export const ptActions = {
  ...actions,
  ...ptSlice.actions
}
