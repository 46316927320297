
import React, { useCallback } from 'react'

import { unitToPx, Button, grayScaleCanvas } from '@smart/design-system'
import { saveAs } from 'file-saver'
import html2canvas from 'html2canvas'
import JSPDF from 'jspdf'
import JSZip from 'jszip'
import { useSelector } from 'react-redux'

import { de } from '../i18n'
import { ptSelectors } from '../store/pricingTool'
const f = { } as any
f.float = 'right'

function cloneCanvas (oldCanvas:HTMLCanvasElement) {
  // create a new canvas
  const newCanvas = document.createElement('canvas')
  const context = newCanvas.getContext('2d')

  // set dimensions
  newCanvas.width = oldCanvas.width
  newCanvas.height = oldCanvas.height

  if (context) {
    context.drawImage(oldCanvas, 0, 0)
  }

  // return the new canvas
  return newCanvas
}
const DownLoad = () => {
  const sizes = useSelector(ptSelectors.sizes)
  const { orientation, colors, cuttingMarks } = useSelector(ptSelectors.form)
  const entites = useSelector(ptSelectors.entities).devices
  const deviceId = useSelector(ptSelectors.form).deviceId

  const go = useCallback(async (stop = false) => {
    const model = deviceId ? entites[deviceId] : {}
    const p = document.body.querySelector('.paper') as HTMLElement
    const elm = document.getElementsByTagName('html')
    p.classList.add('printing')

    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0

    if (elm !== null) {
      while (elm[0].scrollTop !== 0) {
        await new Promise(resolve => setTimeout(resolve, 100))
      }
    }
    const zip = new JSZip()
    const promise = Promise.all(
      sizes.map((s) => {
        const doc = new JSPDF(orientation === '0' ? 'p' : 'l', s.unit, [s.w, s.h])
        const mmWidth = doc.internal.pageSize.getWidth()
        const mmHeight = doc.internal.pageSize.getHeight()
        const pxWidth:number = unitToPx(mmWidth + s.unit)
        const pxHeight:number = unitToPx(mmHeight + s.unit)
        const name = `${model.description} ${s.id}_${s.w}x${s.h}${s.unit} .pdf`
        console.log(`${mmWidth} X ${mmHeight}mm ${pxWidth}X${pxHeight}px`)
        return html2canvas(p, {
          scrollX: 0,
          scrollY: 0,
          scale: 1,
          // width: pxWidth,
          // height: pxHeight,
          useCORS: true,
          allowTaint: true
        }).then(oldCanvas => {
          const canvas = cloneCanvas(oldCanvas)
          const context = canvas.getContext('2d')

          if (!context) return alert('could not generate pdf, please use another browser')

          if (cuttingMarks === '1') {
            context.moveTo(0, 0)
            context.setLineDash([7])
            context.lineWidth = 10
            context.strokeStyle = '#222'
            context.strokeRect(6, 6, canvas.width - 12, canvas.height - 12)
          }

          if (colors === '0') {
            grayScaleCanvas(context, canvas)
          }
          const imgData = canvas.toDataURL('image/png')
          // const n = document.getElementById('test')
          // @ts-ignore
          // n.src = ''
          // @ts-ignore
          // n.src = imgData
          console.log(`composing pdf with image of dimentions : ${mmWidth} X ${mmHeight}mm wXh`)
          doc.addImage(imgData, 'PNG', 0, 0, mmWidth, mmHeight)
          if (sizes.length === 1) {
            doc.save(`${name}.pdf`)
          } else {
            zip.file(name + '.pdf', doc.output('blob'))
          }

          return name
        })
      })
    )

    promise.then(() => {
      p.classList.remove('printing')
      if (sizes.length > 1) {
        zip.generateAsync({ type: 'blob' }).then(function (content:any) {
          saveAs(content, 'Preisschild.zip')
        })
      }
    })
  }, [deviceId, entites, sizes, orientation, cuttingMarks, colors])

  return <Button style={f} primary type="button" onClick={go}>
    {de.download} [{sizes.length}]
  </Button>
}

export { DownLoad }
